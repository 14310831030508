import { render, staticRenderFns } from "./MarketingSources.vue?vue&type=template&id=70ee7a76&scoped=true&"
import script from "./MarketingSources.vue?vue&type=script&lang=js&"
export * from "./MarketingSources.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ee7a76",
  null
  
)

export default component.exports